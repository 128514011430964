<template>
  <div class="proposal__wrap">
    <div class="section shadow filter__wrap">
      <div>
        <el-button type="primary">人才币规则</el-button>
        <el-button type="text" @click="$router.push('/brokerage')">佣金规则</el-button>
      </div>
      <el-button type="primary" icon="el-icon-plus" size="mini" @click="showEdit">添加规则</el-button>
    </div>
    <!-- 表格 -->
    <div class="table__wrap">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="field"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="false"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <template v-slot:action="{row}">
          <el-button type="text" icon="el-icon-edit" @click="showEdit(row)">编辑</el-button>
          <el-button type="text" icon="el-icon-delete" class="delBtn" @click="handleDelete(row)">删除</el-button>  
        </template>
      </VTable>
    </div>

    <edit ref="edit" @refresh="getTable" :diplomaList="diplomaList"></edit>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue'
import { mixinTable } from '@/mixins/table.js'

export default {
  name: 'AgentList',
  mixins:[mixinTable],
  components: {
    VTable,
    Edit,
  },
  data() {
    return {
      field: [
        { name: "type", label: "学历", hidden: false },
        { name: "coin", label: "人才币", hidden: false },
        { name: "update_time", label: "更新时间", hidden: false },
        { name: "action", label: "操作", width: "260", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      diplomaList: [],
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/rule/list', {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data;
          this.table.total = res.data.total;
          this.diplomaList = [];
          if(res.data.length > 0) {
            res.data.forEach(v => {
              this.diplomaList.push(v.type)
            })
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 删除
    handleDelete(row) {
      this.$confirm('请确认删除操作?', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('admin/rule/del', {id: row.id}).then(res => {
          if(res.code === 1) {
            this.$message.success('操作成功！')
            this.getTable();
          }
        })
      }).catch(() => {})
    },
  }
}
</script>

<style scoped lang="scss">
  .flex {
    display: flex;
  }
  .proposal__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;

    .filter__wrap {
      padding: 10px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }

    .table__wrap {
      flex: 1;
      padding: 0;
      box-sizing: border-box;
    }
  }
</style>
<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
    >
      <!-- 编辑不要修改学历 -->
      <el-form-item prop="type" label="学历" v-if="isChange === false">
        <el-select v-model="form.data.type" clearable filterable placeholder="请选择">
          <el-option
            v-for="item in addDiplomaOpt"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 新增 -->
      <el-form-item prop="type" label="学历" v-else>
        <el-select v-model="form.data.type" :disabled="true" clearable filterable placeholder="请选择">
          <el-option
            v-for="item in diplomaOpt"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="coin" label="人才币">
        <el-input  type="age" v-model.number="form.data.coin" clearable placeholder="请输入人才币数量"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'PlatformRuleEdit',
    props: ['diplomaList'],
    data() {
      return {
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            type: '',
            coin: '',
          },
          rules: {
            type: [{ required: true, message:'请选择学历', trigger: 'change'}],
            coin: [
              { required: true, message: '人才币不能为空'},
              { type: 'number', message: '人才币只能输入整数'}
            ],
          }
        },
        curDiplomaList:[],
        addDiplomaOpt: [],
        diplomaOpt: [
          {
            id: 1,
            name: '中专'
          },
          {
            id: 2,
            name: '大专'
          },
          {
            id: 3,
            name: '本科'
          },
          {
            id: 4,
            name: '硕士'
          }
        ]
      }
    },
    watch: {
      diplomaList(val) {
        this.curDiplomaList = val
        // 过滤出未添加过的学历
        let arr = this.diplomaOpt.filter(item => !this.curDiplomaList.some(ele => ele === item.name))
        this.addDiplomaOpt = arr
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true
        common.deepClone(this.form.data, row)
        this.form.data.type = row.type_id
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            this.form.loading = true;
            let apiUrl = !this.isChange ? '/admin/rule/add' : '/admin/rule/edit'
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return isLt5M;
      },
      httpRequest(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
          this.form.data.id_card = res.data.all_path_url;
        })
      },
      handleAccountChange(id) {
        let arr = this.userListOpt.filter(item => {
          return item.id === id
        })
        this.form.data.name = arr[0].nickname
        this.form.data.account = arr[0].account
        this.isdisabled = true;
        
      },
      dataFilter(val) {
        this.form.data.account = val;
        this.form.data.name = '';
        this.isdisabled = false;
        this.form.rules.name = [{ required: true, message:'请输入代理姓名', trigger: 'blur'}]
      }
    }
  }
</script>
